import React, { useEffect, useState } from 'react';
import css from '../../styles/Login.module.scss';
import classNames from 'classnames';
import axios from 'axios';
import PopupFallId from '../popups/PopupFallId';
import PopupMakeDeposit from '../popups/PopupMakeDeposit';
import VideoId from '../OtherPages/VideoId';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

const Signin = ({ setData, setPath, setMenu }) => {
    const { t } = useTranslation([ 'auth' ]);

    const [ popup1, setPopup1 ] = useState(false);
    const [ dataPopup1, setDataPopup1 ] = useState(false);
    const [ popup2, setPopup2 ] = useState(false);
    const [ showVideo, setShowVideo ] = useState(false);
    const [ id, setId ] = useState('');

    const items = [
        t('auth:signin.software.predict'),
        t('auth:signin.software.calculate'),
        t('auth:signin.software.profit'),
    ];

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflow = 'hidden';
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;
        let newValue = value.replace(/\D/g, '');
        setId(newValue);
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_HOST}/v1/api/prediction/login?player_id=${id}`
            );

            const { data, success } = response.data;

            if ( !success ) {
                console.log(new Error());
            } else {
                if ( data.is_login ) {
                    setData(data);
                    setPath('');
                } else if ( !data.is_search ) {
                    setPopup2(true);
                } else if ( !data.is_amount ) {
                    setPopup1(true);
                    setData(data);
                    setDataPopup1(data);
                } else if ( data.is_amount && !data.is_registration ) {
                    setData(data);
                    setPath('signup');
                }
            }
        } catch ( error ) {
            console.error(error);
        }
    };

    return (
        <>
            <div
                className="uiContainer2"
                style={!!showVideo ? { zIndex: '101' } : {}}
            >
                <div className={css.main} style={{ maxHeight: '100dvh' }}>
                    <div className={classNames(css.screen, css._firstScreen)}>
                        <div className="uiBgElement"/>
                        <div className={classNames(css.signin)}>
                            <span className={css.title}>{t('auth:signin.title')}</span>
                            <div className={css.inputContainer}>
                                <label className={css.inputLabel} htmlFor="input-id">
                                    <input
                                        className={classNames(
                                            css.input,
                                            !!id.length && css.inputActive
                                        )}
                                        id="input-id"
                                        type="text"
                                        placeholder="ID"
                                        value={id}
                                        onChange={handleChange}
                                    />
                                </label>
                                <div className={css.inputBorder}/>
                            </div>
                            <div className={css.actions}>
                                <button className="uiButton" onClick={handleSubmit}>
                                    {t('auth:signin.confirm')}
                                </button>
                                <button
                                    className={css.whereId}
                                    onClick={() => setShowVideo(true)}
                                >
                                    {t('auth:signin.whereId')}
                                </button>
                                {showVideo && <VideoId setShowVideo={setShowVideo}/>}
                            </div>
                        </div>
                        <div className={css.showMore}>
                            <span>{t('auth:signin.software.more')}</span>
                            <div className={css.arrow}/>
                        </div>
                    </div>
                    <div
                        className={classNames(css.screen, css.screenSlider)}
                        style={{ paddingLeft: '0', paddingRight: '0' }}
                    >
            <span className={css.titleScreen}>
              {t('auth:signin.software.look')}
            </span>
                        <div className={css.sliderContainer}>
                            <Swiper
                                pagination={{ dynamicBullets: true }}
                                modules={[ Pagination, Navigation ]}
                                navigation
                                loop
                                className="mySwiper"
                            >
                                {[ ...Array(6) ].map((_, index) => (
                                    <SwiperSlide key={index}>
                                        <div
                                            className={css.slide}
                                            style={{
                                                backgroundImage: `url("/images/bg/${index + 1}.png")`,
                                            }}
                                        >
                                            <div
                                                className={css.slideContent}
                                                style={{
                                                    backgroundImage: `url("/images/slide/de/${
                                                        (index + 1) * 11
                                                    }.png")`,
                                                }}
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    <div
                        className={classNames(css.screen, css.screenSlider, css._bg)}
                        style={{ padding: '0px', backgroundImage: '$background' }}
                    >
            <span className={css.titleScreen}>
              {t('auth:signin.software.about')}
            </span>
                        <div className={css.info}>
                            {items.map((text, index) => (
                                <div className={css.item} key={index}>
                                    <div className={css.num}>{index + 1}</div>
                                    <p className={css.text}>{text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={classNames(css.screen, css.screenSlider)}
                        style={{ paddingLeft: '0', paddingRight: '0' }}
                    >
            <span className={css.titleScreen}>
              {t('auth:signin.software.reviews')}
            </span>
                        <div className={css.sliderContainer}>
                            <Swiper
                                pagination={{ dynamicBullets: true }}
                                modules={[ Pagination, Navigation ]}
                                navigation
                                loop
                                className="mySwiper"
                            >
                                {[ ...Array(16) ].map((_, index) => (
                                    <SwiperSlide key={index}>
                                        <div className={css.slide} style={{ height: '485px' }}>
                                            <div
                                                className={css.slideContent}
                                                style={{
                                                    backgroundImage: `url("/images/comments/de/${
                                                        index + 1
                                                    }.png")`,
                                                    backgroundSize: 'cover',
                                                }}
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            {popup1 && (
                <PopupMakeDeposit
                    data={dataPopup1}
                    setShowPopup={setPopup1}
                    setMenu={setMenu}
                />
            )}
            {popup2 && <PopupFallId setShowPopup={setPopup2}/>}
        </>
    );
};

export default Signin;
