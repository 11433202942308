import React, { useState } from "react";
import css from "../../styles/Popup.module.scss";
import styles from "../../styles/Login.module.scss";
import classNames from "classnames";
import VideoDeposit from "../OtherPages/VideoDeposit";
import numeral from "numeral";
import { useTranslation, Trans } from "react-i18next";

const PopupMakeDeposit = ({ setShowPopup, data, setMenu }) => {
  const { t } = useTranslation(["auth"]);
  const [showVideo, setShowVideo] = useState(false);

  const renderInfoText = () => {
    //todo: backend local currency
    const balanceRequiredFormatted = numeral(data.balance_required).format("0");
    const balanceRequiredLocal = numeral(data.balance_required * 100).format(
      "0"
    );
    const insufficientDeposit =
      data.insufficient_deposit <= 0 ? 0 : data.insufficient_deposit;
    const insufficientDepositFormatted =
      numeral(insufficientDeposit).format("0");
    const insufficientDepositLocal = numeral(insufficientDeposit * 100).format(
      "0"
    );

    const values = {
      balanceRequiredFormatted,
      balanceRequiredLocal,
      insufficientDepositFormatted,
      insufficientDepositLocal,
    };

    return (
      <p className={classNames(css.text, css._t2)}>
        <Trans
          i18nKey="auth:popups.makeDeposit.syncText"
          values={values}
          components={{ span: <span /> }}
        />
      </p>
    );
  };

  return (
    <div className={css.popupContainer}>
      <div className={css.popup}>
        <button className={css.close} onClick={() => setShowPopup(false)} />

        <h2 className={classNames(css.title, css._t2)}>
          {t("auth:popups.makeDeposit.title")}
        </h2>

        {renderInfoText()}

        <button
          className="uiButton"
          style={{ marginTop: "20px" }}
          onClick={() => setMenu(11)}
        >
          {t("auth:popups.makeDeposit.makeDeposit")}
        </button>

        <button
          className="uiButton uiButton_2"
          style={{ marginTop: "10px" }}
          onClick={() => window.open(`https://t.me/${data.username}`, "_blank")}
        >
          {t("auth:popups.makeDeposit.help")}
        </button>

        <button className={styles.btnModal}>
          {t("auth:popups.makeDeposit.whyNeed")}
        </button>

        <div className={css.blockVideo}>
          <button className={css.play} onClick={() => setShowVideo(true)} />
          {showVideo && <VideoDeposit setShowVideo={setShowVideo} />}
        </div>
      </div>
    </div>
  );
};

export default PopupMakeDeposit;
