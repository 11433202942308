import React from "react";
import css from "../styles/InstallPage.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const IOS = ({ iosActive, setIosActive }) => {
  const { t } = useTranslation(["install"]);

  return (
    <div className={classNames(css.iosContent, iosActive && css._active)}>
      <header className={classNames(css.head, "head")}>
        <div className={css.logo} />
      </header>
      <section className={css.body}>
        <div className={css.backContainer}>
          <button className={css.back} onClick={() => setIosActive(false)}>
            {t("install:ios.button")}
          </button>
        </div>
        <h1 className={css.title}>{t("install:ios.howTo")}</h1>
        <div className={css.step}>
          <div className={css.num}> 1 </div>
          <div className={css.stepTitle}>{t("install:ios.click")}</div>
        </div>
        <div className={css.imageContainer}>
          <img src="images/slideForInstall/de/d1.png" alt="" />
        </div>
        <div className={css.step}>
          <div className={css.num}> 2 </div>
          <div className={css.stepTitle}>{t("install:ios.addHome")}</div>
        </div>
        <div className={css.imageContainer}>
          <img src="images/slideForInstall/de/d2.png" alt="" />
        </div>
        <div className={css.step}>
          <div className={css.num}> 3 </div>
          <div className={css.stepTitle}>{t("install:ios.appAppear")}</div>
        </div>
      </section>
    </div>
  );
};

export default IOS;
