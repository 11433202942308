import React, { useEffect, useState } from 'react';
import css from '../../styles/OtherPages.module.scss';
import classNames from 'classnames';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

const History = ({ menu, setMenu }) => {
    const { t } = useTranslation([ 'common' ]);

    const [ users, setUsers ] = useState(0);
    const [ winners, setWinners ] = useState(0);
    const [ rounds, setRounds ] = useState([]);
    const [ showRounds, setShowRounds ] = useState(10);
    const [ usersCount, setUsersCount ] = useState();
    const [ stage, setStage ] = useState(0);
    const [ winnersCount, setWinnersCount ] = useState();
    const [ stage2, setStage2 ] = useState(0);

    useEffect(() => {
        if ( menu !== 4 ) return;
        getHistory();
    }, [ menu ]);

    useEffect(() => {
        const usersString = numeral(users).format('0,0');
        const timeoutId = setTimeout(() => {
            setStage(1);

            const timeoutId2 = setTimeout(() => {
                setUsersCount(usersString);
                setStage(0);
            }, 500);

            return () => clearTimeout(timeoutId2);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [ users ]);

    useEffect(() => {
        const winnersString = numeral(winners).format('0,0');
        const timeoutId2 = setTimeout(() => {
            setStage2(1);

            const timeoutId22 = setTimeout(() => {
                setWinnersCount(winnersString);
                setStage2(0);
            }, 500);

            return () => clearTimeout(timeoutId22);
        }, 500);

        return () => clearTimeout(timeoutId2);
    }, [ winners ]);

    const getHistory = async () => {
        try {
            //todo
            const response = await fetch(
                `${process.env.REACT_APP_LINK}/api/odds/rounds`
            );

            const { ok, json } = await response.json();

            // const response = {
            //     ok: true, // Simulate a successful fetch call
            //     json: async () => ({
            //         statistics: {
            //             count_users: 123456, // Example total number of user
            //             amount_winnings: 7890123, // Example total amount of winnings
            //         },
            //         history: [
            //             { id: 1, coefficient: '1.25', user: 100, winners: 25 },
            //             { id: 2, coefficient: '2.50', user: 200, winners: 50 },
            //             { id: 3, coefficient: '5.00', user: 150, winners: 30 },
            //             { id: 4, coefficient: '10.00', user: 250, winners: 75 },
            //             { id: 5, coefficient: '20.00', user: 300, winners: 100 },
            //         ],
            //     }),
            // };

            if ( !ok ) throw new Error('Network response was not ok');
            const statistics = {};
            statistics.count_users = json?.[ 0 ].users;
            statistics.amount_winnings = json?.[ 0 ].winners;

            setUsers(statistics.count_users);
            setWinners(statistics.amount_winnings);

            setRounds(json);
        } catch ( error ) {
            console.error(error);
        }
    };

    return (
        <div className={classNames(css.container, menu === 4 && css._show)}>
            <div className={css.content}>
                <section className={css.backContainer}>
                    <button className={css.back} onClick={() => setMenu(1)}>
                        SOFT
                    </button>
                </section>

                <section className={css.history}>
                    <div className={css.historyHeader}>
                        <div className={css.historyStats}>
                            <div className={css.sec1}>
                                <div className={css.titles}>
                                    <span>{t('common:history.headerHistory.headerHistory1')}</span>
                                    <span>{t('common:history.headerHistory.headerHistory2')}</span>
                                </div>
                            </div>
                            <div className={classNames(css.sec2, stage === 1 && css._rotate)}>
                                <div className={css.titles}>
                                    <span>{numeral(usersCount).format('0,0')}</span>
                                    <span>{t('common:history.headerHistory.users')}</span>
                                </div>
                            </div>
                            <div
                                className={classNames(css.sec3, stage2 === 1 && css._rotate)}
                            >
                                <div className={css.titles}>
                                    <span>{numeral(winnersCount).format('0,0')}{t('currency')}</span>
                                    <span>{t('common:history.headerHistory.winnings')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={css.historyBody}>
                        <div
                            className={classNames(css.historyStats, css._t2)}
                            style={{ backgroundColor: 'rgba(30, 31, 40, 1)' }}
                        >
                            <div
                                className={classNames(css.sec2, css._t2)}
                                style={{ width: '33.3333%' }}
                            >
                                <div className={classNames(css.titles, css._t2)}>
                  <span className={css.usersTitle}>
                    {t('common:history.tableHeader.users')}
                  </span>
                                </div>
                            </div>
                            <div
                                className={classNames(css.sec3, css._t2)}
                                style={{ width: '33.3333%' }}
                            >
                                <div className={classNames(css.titles, css._t2)}>
                    <span className={css.usersTitle}>
                      {t('common:history.tableHeader.winnings')}
                    </span>
                                </div>
                            </div>
                            <div className={css.secCoef} style={{ width: '33.3333%' }}>
                                <div className={css.titleResult}>
                                    {t('common:history.tableHeader.result')}
                                </div>
                            </div>
                        </div>
                        <div className={css.historyBody}>
                            {rounds.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        {index + 1 <= showRounds && (
                                            <div className={classNames(css.historyStats, css._t2)}>
                                                <div
                                                    className={classNames(css.sec2, css._t2)}
                                                    style={{ width: '33.3333%' }}
                                                >
                                                    <div className={classNames(css.titles, css._t2)}>
                                                        <span className={css.usersTitle}>
                                                          {numeral(item.users).format('0,0')}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classNames(css.sec3, css._t2)}
                                                    style={{ width: '33.3333%' }}
                                                >
                                                    <div className={classNames(css.titles, css._t2)}>
                                                        <span className={css.usersTitle}>
                                                          {`${numeral(item.winners).format('0,0')}${t('currency')}`}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className={css.secCoef}
                                                    style={{ width: '33.3333%' }}
                                                >
                                                    <div
                                                        className={classNames(
                                                            css.coeff,
                                                            item.coefficient >= 1 &&
                                                            item.coefficient <= 1.99 &&
                                                            css._blue,
                                                            item.coefficient >= 2 &&
                                                            item.coefficient <= 9.99 &&
                                                            css._purple,
                                                            item.coefficient >= 10 &&
                                                            item.coefficient <= 99.99 &&
                                                            css._pink,
                                                            item.coefficient >= 100 && css._gold
                                                        )}
                                                    >
                                                        {numeral(item?.coefficient).format('0.00')}x
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            {rounds.length > 10 && showRounds <= rounds.length && (
                                <button
                                    className={css.showMore}
                                    onClick={() => setShowRounds((prev) => prev + 10)}
                                >
                                    {t('common:history.buttons.showMore')}
                                </button>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default History;
