import React, { useRef, useState, useEffect } from "react";
import css from "../styles/Container.module.scss";
import classNames from "classnames";
import anime from "animejs";
import { Trans } from "react-i18next";

const IncraceChance = ({ data, setMenu }) => {
  const divRef = useRef();

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (!Object.keys(data).length) return;
    const perc = data.percent;

    setTimeout(() => {
      anime({
        targets: divRef.current,
        innerHTML: [0, perc],
        easing: "linear",
        duration:
          perc >= 0 && perc <= 25 ? 1000 : perc > 25 && perc < 50 ? 2000 : 2500,
        round: 100,
        update: function (anim) {
          divRef.current.innerHTML = `${parseFloat(
            anim.animations[0].currentValue
          ).toFixed(2)}%`;
          setPercent(parseFloat(anim.animations[0].currentValue).toFixed(2));
        },
      });
    }, 500);
  }, [data]);

  return (
    <section className={css.bottom}>
      <div className={css.progressLineContainer}>
        <div className={css.progress}>
          <div
            className={classNames(
              css.line,
              percent <= 39.99 && css._red,
              percent >= 40 && percent <= 79 && css._gold,
              percent >= 79.01 && css._green
            )}
            style={{
              width: `${percent}%`,
            }}
          ></div>
        </div>
        <div className={css.chanceContainer}>
          <div className={css.banner} onClick={() => setMenu(5)}>
            <span>
              <Trans i18nKey="common:increaseChance.title" />
            </span>
          </div>
          <div className={css.proc}>
            <span ref={divRef}> </span>
            <span>
              <Trans i18nKey="common:increaseChance.progress.chance" />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IncraceChance;
