import React from 'react';
import css from '../../styles/OtherPages.module.scss';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

const Help = ({ menu, setMenu, percent = 0, leadData }) => {
    const { t } = useTranslation([ 'common' ]);

    return (
        <div className={classNames(css.container, menu === 2 && css._show)}>
            <div className={css.content}>

                <section className={css.backContainer}>
                    <button className={css.back} onClick={() => setMenu(1)}>
                        {t('common:help.backButton')}
                    </button>
                </section>

                <section className={css.help}>
                    <div className={css.contentHelp}>
                        <div className={css.title}>
                            <Trans
                                i18nKey="common:help.questions.title"
                                components={{ span: <span/> }}
                            ></Trans>
                        </div>
                        <button
                            onClick={() =>
                                window.open(`https://t.me/${leadData.username}`, '_blank')
                            }
                        >
                            <span>{t('common:help.questions.button')}</span>
                        </button>
                        <span className={css.info}>{t('common:help.questions.info')}</span>
                    </div>
                </section>

                <section className={css.help}>
                    <div className={css.contentHelp}>
                        <div className={css.title}>
                            <Trans
                                i18nKey="common:help.feedback.title"
                                components={{ span: <span/> }}
                            ></Trans>
                        </div>
                        <button onClick={() =>
                            window.open(`${leadData.tg_link}`, '_blank')
                        }>
                            <span>{t('common:help.feedback.button')}</span>
                        </button>
                        <span className={css.info}>{t('common:help.feedback.info')}</span>
                    </div>
                </section>

            </div>
        </div>
    );
};

export default Help;
