import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations_en from './locales/en/en.json';
import translations_de from './locales/de/de.json';
import translations_en_install from './locales/en/install.json';
import translations_de_install from './locales/de/install.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            de: Object.assign({}, translations_de, translations_de_install),
            en: Object.assign({}, translations_en, translations_en_install),
        },
        fallbackLng: 'de',
        nonExplicitSupportedLngs: true,
        load: 'languageOnly',
        detection: {
            order: [ 'localStorage', 'navigator' ],
            caches: [ 'localStorage' ],
            lookupLocalStorage: 'language',
        },
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n;
