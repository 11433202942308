import React from "react";
import css from "../../styles/PopupDisclaimer.module.scss";
import classNames from "classnames";
import { setWithExpiry } from "../../utils/localStorage";
import { Trans } from "react-i18next";

const DisclaimerPopup = ({ setShowPopup }) => {
  const handleMoreClick = () => {
    setWithExpiry("disclaimer", true, 30 * 24 * 60 * 60 * 1000);
    setShowPopup(false);
  };

  return (
    <div className={css.popupContainer}>
      <div className={css.popup}>
        <div className={css.scrollableContent}>
          <div className={css.mainTitleWithIcon}>
            <div className={css.icon}></div>
            <div className={css.title}><Trans i18nKey="common:disclaimer.mainTitle"></Trans></div>
          </div>
          <div className={css.infoMessage}>
            <Trans i18nKey="common:disclaimer.infoMessage"></Trans>
          </div>
          <br />
          <div className={css.titleWithIcon}>
            <div className={css.iconQuestion}></div>
            <div className={css.title}><Trans i18nKey="common:disclaimer.howItWorksTitle"></Trans></div>
          </div>

          <div className={css.regText}>
            <Trans
              i18nKey="common:disclaimer.howItWorksText"
              components={{ span: <span />, b: <b /> }}
            ></Trans>
          </div>
          <img className={css.image} src="images/disclaimer/de/d_1.png" alt="" />
          <div className={css.regText}>
            <b>
              <Trans i18nKey="common:disclaimer.gameIntegrityTitle"></Trans>
            </b>
          </div>
          <div className={css.regText}>
            <b>
              <span>
                <Trans i18nKey="common:disclaimer.gameIntegrityText2"></Trans>
              </span>
            </b>
          </div>
          <br />
          <div className={css.regText}>
            <Trans
              i18nKey="common:disclaimer.softwareSupportText"
              components={{ b: <b /> }}
            ></Trans>
          </div>
          <br />
          <div className={css.regText}>
            <Trans
              i18nKey="common:disclaimer.dependenceWarning"
              components={{ b: <b /> }}
            ></Trans>
          </div>
          <br />
          <div className={css.titleWithIcon}>
            <div className={css.iconAdvice}></div>
            <div className={css.title}>
              <Trans i18nKey="common:disclaimer.adviceTitle"></Trans>
            </div>
          </div>
          <div className={css.regText}>
            <b>
              <Trans i18nKey="common:disclaimer.adviceText"></Trans>
            </b>
          </div>
          <img className={css.image} src="images/disclaimer/de/d_2.png" alt="" />
          <div className={css.regText}>
            <Trans
              i18nKey="common:disclaimer.personalStrategiesText"
              components={{ b: <b />, span: <span /> }}
            ></Trans>
            <br />
            <Trans
              i18nKey="common:disclaimer.goodLuck"
              components={{ b: <b />, span: <span /> }}
            ></Trans>
          </div>
          <button
            className={classNames(css.button, css.agree)}
            onClick={handleMoreClick}
          >
            <Trans i18nKey="common:disclaimer.agreeButtonText"></Trans>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerPopup;
