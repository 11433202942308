import { useTranslation } from "react-i18next";
import classNames from "classnames";
import css from "../styles/Menu.module.scss";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.languages[0];

  const languages = [
    { code: "en", label: "ENG" },
    { code: "de", label: "DEU" },
  ];

  return (
    <div
      className={css.languages}
      onClick={() => i18n.changeLanguage(currentLang === "en" ? "de" : "en")}
    >
      <div className={css.languageContainer}>
        {languages.map(({ code, label }) => (
          <div
            key={code}
            className={classNames(
              css.language,
              currentLang === code && css._active
            )}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};
