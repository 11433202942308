import React, { createContext, useEffect, useState } from "react";
import css from "./styles/Menu.module.scss";
import classNames from "classnames";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Container from "./components/Container";
import Signup from "./components/Login/Signup";
import Signin from "./components/Login/Signin";
import IncreaseChance from "./components/OtherPages/IncreaseChance";
import Help from "./components/OtherPages/Help";
import HowToPlay from "./components/OtherPages/HowToPlay";
import History from "./components/OtherPages/History";
import PredictionLimit from "./components/OtherPages/PredictionLimit";
import { LanguageSwitcher } from "./components/LanguageSwitcher";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n/i18n.config";
import { getWithExpiry } from "./utils/localStorage";
import DisclaimerPopup from "./components/popups/DisclaimerPopup";

export const Context = createContext();

const App = () => {
  const { t } = useTranslation(["common"]);
  const [path, setPath] = useState("signin");
  const [data, setData] = useState("");
  const [leadData, setLeadData] = useState({});
  const [limit, setLimit] = useState({});
  const [percent, setPercent] = useState({});
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const [menu, setMenu] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.fonts.ready
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Fonts error:", error);
      });
  }, []);

  useEffect(() => {
    const storeId = JSON.parse(localStorage.getItem("aviatorUser"));
    if (!!storeId) {
      setData(storeId);
      setLeadData(storeId);
      setPath("");
    }
  }, []);

  useEffect(() => {
    const disclaimer = getWithExpiry("disclaimer");
    if ((path === "" || !path) && !disclaimer) {
      setShowDisclaimer(true);
    }
  }, [path]);

  return (
    <I18nextProvider i18n={i18n}>
      {isLoading && (
        <div className="uiLoaderContainer">
          <span className="loader"> </span>
        </div>
      )}
      <>
        <Context.Provider>
          <section className={classNames(css.head, "head")}>
            <div className={css.logo} />
            <LanguageSwitcher />
          </section>
          {(path === "" || !path) && (
            <>
              {showDisclaimer && (
                <DisclaimerPopup setShowPopup={setShowDisclaimer} />
              )}
              <IncreaseChance
                menu={menu}
                setMenu={setMenu}
                leadData={leadData}
                currentLimit={limit}
              />
              <Help menu={menu} setMenu={setMenu} leadData={leadData} />
              <HowToPlay menu={menu} setMenu={setMenu} leadData={leadData} />
              <History menu={menu} setMenu={setMenu} leadData={leadData} />
              <PredictionLimit
                menu={menu}
                setMenu={setMenu}
                limit={limit}
                percent={percent}
                leadData={leadData}
              />
              <div className="uiContainer">
                <div className="uiBgElement" />
                <Container
                  data={data}
                  setMenu={setMenu}
                  setLimit={setLimit}
                  setPercent={setPercent}
                  setLeadData={setLeadData}
                />
              </div>
              <div className={classNames(css.menuContainer, "menu")}>
                <div className={css.menu}>
                  <div
                    className={classNames(css.item, menu === 1 && css._active)}
                    onClick={() => setMenu(1)}
                  >
                    <span> {t("common:menu.aviasoft")} </span>
                  </div>
                  <div
                    className={classNames(css.item, menu === 3 && css._active)}
                    onClick={() => setMenu(3)}
                  >
                    <span> {t("common:menu.howToPlay")} </span>
                  </div>
                  <div
                    className={classNames(css.item, menu === 2 && css._active)}
                    onClick={() => setMenu(2)}
                  >
                    <span> {t("common:menu.help")} </span>
                  </div>
                  <div
                    className={classNames(css.item, menu === 4 && css._active)}
                    onClick={() => setMenu(4)}
                  >
                    <span> {t("common:menu.history")} </span>
                  </div>
                </div>
              </div>
            </>
          )}
          {path === "signup" && (
            <Signup
              userData={data}
              setUserData={setData}
              setPath={setPath}
              setMenu={setMenu}
            />
          )}
          {path === "signin" && (
            <Signin setData={setData} setPath={setPath} setMenu={setMenu} />
          )}
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </Context.Provider>
      </>
    </I18nextProvider>
  );
};

export default App;
