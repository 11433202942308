import React, { useState } from "react";
import css from "../../styles/Popup.module.scss";
import styles from "../../styles/Login.module.scss";
import classNames from "classnames";
import VideoId from "../OtherPages/VideoId";
import { useTranslation } from "react-i18next";

const PopupFallId = ({ setShowPopup }) => {
  const { t } = useTranslation(["auth"]);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div
      className={css.popupContainer}
      style={
        showVideo
          ? {
              zIndex: "110",
            }
          : {}
      }
    >
      <div className={css.popup}>
        <button className={css.close} onClick={() => setShowPopup(false)} />
        <h2 className={classNames(css.title, css._t2)}>
          {t("auth:popups.fallId.title")}
        </h2>
        <p className={classNames(css.text, css._t2)}>
          {t("auth:popups.fallId.notFound")}
        </p>
        <button
          className={"uiButton"}
          style={{
            marginTop: "15px",
          }}
          onClick={() => setShowPopup(false)}
        >
          {t("auth:popups.fallId.tryAgain")}
        </button>
        <button className={styles.btnModal} onClick={() => setShowVideo(true)}>
          {t("auth:popups.fallId.whereId")}
        </button>
        {showVideo && <VideoId setShowVideo={setShowVideo} />}
      </div>
    </div>
  );
};

export default PopupFallId;
