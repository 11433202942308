import React from 'react';
import css from '../../styles/OtherPages.module.scss';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

const HowToPlay = ({ menu, setMenu, percent = 0, leadData }) => {
    return (
        <div className={classNames(css.container, menu === 3 && css._show)}>
            <div className={css.content}>
                <section className={css.backContainer}>
                    <button className={css.back} onClick={() => setMenu(1)}>
                        <Trans i18nKey="common:howToPlay.backButton"></Trans>
                    </button>
                </section>
                <section className={css.howPlayContainer}>
                    <div className={css.title}>
                        <>
                            <Trans
                                i18nKey="common:howToPlay.titleWithChance"
                                components={{ span: <span/>, br: <br/> }}
                            ></Trans>
                        </>
                    </div>
                    <p className={css.text}>
                        <Trans
                            i18nKey="common:howToPlay.softwareDescription"
                            components={{ br: <br/> }}
                        ></Trans>
                    </p>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div className={css.title}>
                        <Trans
                            i18nKey="common:howToPlay.titleWithPrevRound"
                            components={{ span: <span/>, br: <br/> }}
                        ></Trans>
                    </div>
                    <p className={css.text}>
                        <Trans
                            i18nKey="common:howToPlay.increaseChancesText"
                            components={{ span: <span/>, br: <br/> }}
                        ></Trans>
                    </p>
                    <div className={css.step}>
                        <div className={css.num}> 1</div>

                        <p>
                            <Trans
                                i18nKey="common:howToPlay.step1TopUp"
                                components={{ span: <span/> }}
                            ></Trans>
                        </p>
                    </div>
                    <div className={css.step}>
                        <div className={css.num}> 2</div>
                        <p>
                            <Trans
                                i18nKey="common:howToPlay.step2Click"
                                components={{ span: <span/> }}
                            ></Trans>
                        </p>
                        <div className={css.imageIncraceChance}/>
                    </div>
                    <p className={css.text}>
                        <Trans i18nKey="common:howToPlay.previousRoundBlock"></Trans>
                    </p>
                    <div className={css.arrowBottom}/>
                    <div className={css.imagePrevRound}/>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div className={css.step}>
                        <div className={css.num}> 1</div>
                        <p>
                            <Trans i18nKey="common:howToPlay.increasedChance"></Trans>
                        </p>
                    </div>
                    <div className={css.step}>
                        <div className={css.num}> 2</div>
                        <p>
                            <Trans i18nKey="common:howToPlay.topUpBalance"></Trans>
                        </p>
                    </div>
                    <div className={css.step}>
                        <div className={css.num}> 3</div>
                        <p>
                            <Trans
                                i18nKey="common:howToPlay.increaseChanceWin"
                                components={{ span: <span/> }}
                            ></Trans>
                        </p>
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div className={css.title}>
                        <Trans
                            i18nKey="common:howToPlay.roundStatesTitle"
                            components={{ br: <br/> }}
                        />
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div className={css.titleStep}>
                        <Trans
                            i18nKey="common:howToPlay.step1Title"
                            components={{ span: <span/> }}
                        />
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div
                        className={css.text}
                        style={{
                            marginTop: '0px',
                        }}
                    >
                        <Trans i18nKey="common:howToPlay.step1Text"/>
                    </div>
                    <div className={css.howPlayImage}>
                        <img src="images/howPlay/de/1.png" alt=""/>
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div className={css.titleStep}>
                        <Trans
                            i18nKey="common:howToPlay.step2Title"
                            components={{ span: <span/> }}
                        />
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div
                        className={css.text}
                        style={{
                            marginTop: '0px',
                        }}
                    >
                        <Trans i18nKey="common:howToPlay.step2Text"/>
                    </div>

                    <div className={css.howPlayImage}>
                        <img src="images/howPlay/de/2.png" alt=""/>
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div className={css.titleStep}>
                        <Trans
                            i18nKey="common:howToPlay.step3Title"
                            components={{ span: <span/> }}
                        />
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div
                        className={css.text}
                        style={{
                            marginTop: '0px',
                        }}
                    >
                        <Trans i18nKey="common:howToPlay.step3Text"/>
                    </div>

                    <div className={css.howPlayImage}>
                        <img src="images/howPlay/de/3.png" alt=""/>
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div className={css.titleStep}>
                        <Trans
                            i18nKey="common:howToPlay.step4Title"
                            components={{ span: <span/> }}
                        />
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div
                        className={css.text}
                        style={{
                            marginTop: '0px',
                        }}
                    >
                        <Trans i18nKey="common:howToPlay.step4Text"/>
                    </div>

                    <div className={css.howPlayImage}>
                        <img src="images/howPlay/de/4.png" alt=""/>
                    </div>
                </section>
                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div className={css.titleStep}>
                        <Trans
                            i18nKey="common:howToPlay.step5Title"
                            components={{ span: <span/> }}
                        />
                    </div>
                </section>

                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div
                        className={css.text}
                        style={{
                            marginTop: '0px',
                        }}
                    >
                        <Trans i18nKey="common:howToPlay.step5Text"/>
                    </div>

                    <div className={css.howPlayImage}>
                        <img src="images/howPlay/de/5.png" alt=""/>
                    </div>
                </section>

                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div className={css.titleStep}>
                        <Trans
                            i18nKey="common:howToPlay.step6Title"
                            components={{ span: <span/> }}
                        />
                    </div>
                </section>

                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div
                        className={css.text}
                        style={{
                            marginTop: '0px',
                        }}
                    >
                        <Trans i18nKey="common:howToPlay.step6Text"/>
                    </div>

                    <div className={css.howPlayImage}>
                        <img src="images/howPlay/de/6.png" alt=""/>
                    </div>
                </section>

                <section className={classNames(css.howPlayContainer, css.howPlay)}>
                    <div
                        className={css.title}
                        style={{
                            fontSize: '16px',
                        }}
                    >
                        <Trans
                            i18nKey="common:howToPlay.bottomTitle"
                            components={{ br: <br/> }}
                        />
                    </div>
                </section>

                <section
                    className={css.buttons}
                    style={{
                        margin: '20px 0px',
                    }}
                >
                    <button className="uiButton" onClick={(event) => {
                        event.preventDefault();
                        window.open(
                            `https://www.raceupcasino.com/de/game/aviator`,
                            '_blank'
                        );
                    }}>
                        <Trans
                            i18nKey="common:howToPlay.playButton"
                            components={{ span: <span/> }}
                        />
                    </button>
                    <button
                        className="uiButton uiButton_2"
                        onClick={(event) =>
                            window.open(`https://t.me/${leadData.username}`, '_blank')
                        }
                    >
                        <Trans i18nKey="common:howToPlay.helpButton"/>
                    </button>
                </section>

            </div>
        </div>
    );
};

export default HowToPlay;
