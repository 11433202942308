/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import css from "../../styles/Login.module.scss";
import classNames from "classnames";
import axios from "axios";
import { PhoneNumberUtil } from "google-libphonenumber";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const Signup = ({ userData, setUserData, setPath }) => {
  const [data, setData] = useState({});
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const { t } = useTranslation(["auth"]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    if (name === "email") {
      setIsValidEmail(true);
    }
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/[^+\d]/g, "");
    setData((prev) => ({ ...prev, phone: sanitizedValue }));
    setIsValidPhone(true);
  };

  const handleSubmit = async () => {
    const isPhoneValidResult = isPhoneValid(data.phone);
    setIsValidPhone(isPhoneValidResult);

    if (!isPhoneValidResult) {
      toastError(t("auth:signup.errors.phoneFormat"));
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailPattern.test(data.email);
    setIsValidEmail(isEmailValid);

    if (!isEmailValid) {
      toastError(t("auth:signup.errors.emailFormat"));
      return;
    }

    try {
      const sanitizedData = {
        phone: data.phone.replace(/[^0-9]+/g, ""),
        email: data.email,
        player_id: userData.player_id,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/v1/api/prediction/registration`,
        sanitizedData
      );

      if (response.data?.success) {
        setUserData(response.data.data);
        setPath("");
      } else {
        const errors = Object.values(response.data?.data || {});
        errors.forEach((error, index) => {
          setTimeout(() => toastError(error[0]), index * 500);
        });
      }
    } catch (error) {
      console.error(error);
      toastError(t("auth:signup.errors.registration"));
    }
  };

  const toastError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });
  };

  return (
    <div className="uiContainer2">
      <div className={css.main}>
        <div className={classNames(css.screen, css._firstScreen)}>
          <div className="uiBgElement" />
          <div className={classNames(css.signin, css._signup)}>
            <span className={css.title}>{t("auth:signup.title")}</span>
            <div className={css.inputContainer}>
              <label
                className={classNames(css.inputLabel, css._phone)}
                htmlFor="input-phone"
              >
                <input
                  className={classNames(
                    css.input,
                    data.phone?.length && css.inputActive
                  )}
                  id="input-phone"
                  type="tel"
                  placeholder={t("auth:signup.phone")}
                  value={data.phone || ""}
                  name="phone"
                  onChange={handlePhoneChange}
                />
              </label>
              <div className={css.inputBorder} />
            </div>
            <div className={css.inputContainer}>
              <label
                className={classNames(css.inputLabel, css._email)}
                htmlFor="input-email"
              >
                <input
                  className={classNames(
                    css.input,
                    data.email?.length && css.inputActive
                  )}
                  id="input-email"
                  type="text"
                  placeholder={t("auth:signup.email")}
                  value={data.email || ""}
                  name="email"
                  onChange={handleChange}
                />
              </label>
              <div className={css.inputBorder} />
            </div>
            <div className={css.actions}>
              <button className="uiButton" onClick={handleSubmit}>
                {t("auth:signup.confirm")}
              </button>
              <button className="uiButton uiButton_2">
                {t("auth:signup.help")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
