/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/InstallPage.module.scss";
import classNames from "classnames";
import IOS from "./IOS";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "../i18n/i18n.config";

const Install = ({ device }) => {
  const [status, setStatus] = useState("download");
  const [progress, setProgress] = useState(0);
  const [iosActive, setIosActive] = useState(false);
  const duration = 12000;
  const totalSteps = 30;
  const intervalTime = duration / totalSteps;

  const { t } = useTranslation(["install"]);
  const comments = t("install:comments", { returnObjects: true });

  window.isPWA = function () {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://")
    );
  };

  useEffect(() => {
    if (status !== "downloading") return;
    let steps = 0;

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const randomIncrease = Math.floor(Math.random() * 5) + 1;
        const newProgress = Math.min(prevProgress + randomIncrease, 100);
        return newProgress;
      });

      steps += 1;

      if (steps >= totalSteps || progress >= 100) {
        setStatus("install");
        clearInterval(interval);
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [progress, status]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", async function (e) {
      e.preventDefault();
      setStatus("install");
      window.deferredPrompt = e;
      console.log(e);
    });

    // todo исправить ошибку, нет функции такой
    // let intervalId = setInterval(async () => {
    //   let appInstalled = await navigator.getInstalledRelatedApps();
    //
    //   if (appInstalled.length > 0) {
    //     setStatus("open");
    //
    //     clearInterval(intervalId);
    //   }
    // }, 500);
  }, []);

  const handleDownload = () => {
    if (device.deviceType === "Desktop") {
      return setStatus("install");
    }
    if (device.deviceType === "IOS") {
      const body = document.querySelector("body");
      body.scrollTo(0, 0);

      return setIosActive(true);
    }
    if (device.deviceType === "Android") {
      setStatus("downloading");
    }
  };

  const handleInstall = () => {
    setStatus("installing");
    if (window.deferredPrompt != null) {
      window.deferredPrompt.prompt();
    }
  };

  const handleOpen = () => {
    if (window.isPWA()) {
      window.location.href = "/pwa";
    } else {
      window.location.href = "/pwa";
    }
  };

  return (
    <I18nextProvider i18n={i18n}>
      <div
        className={classNames(css.androidContainer, iosActive && css._hidden)}
      >
        <div className={css.androidContent}>
          <section className={css.logoSection}>
            <div className={css.logo} />
          </section>
          <section className={css.infoSection}>
            <div className={css.info}>
              <div className={css.reviews}>
                <span> {t("install:appRate")} </span>{" "}
                <span> {t("install:appReviews")} </span>
              </div>
              <div className={css.size}>
                <span> </span> <span> {t("install:appSize")} </span>
              </div>
              <div className={css.downloads}>
                <span> {t("install:downloadsCount")} </span>{" "}
                <span> {t("install:downloads")} </span>
              </div>
            </div>
          </section>
          <section className={css.downloadSection}>
            {status === "download" && (
              <button
                className={classNames(css.button, css.buttonDownload)}
                onClick={handleDownload}
              >
                <span> {t("install:download")} </span>
              </button>
            )}
            {status === "downloading" && (
              <button className={classNames(css.button, css.buttonDownloading)}>
                <span> {t("install:download")} </span>
                <div
                  className={css.downloading}
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </button>
            )}
            {status === "installing" && (
              <button className={classNames(css.button, css.buttonInstalling)}>
                <span> {t("install:installing")} </span>
                <div className={css.installing} />
              </button>
            )}
            {status === "install" && (
              <button
                className={classNames(css.button, css.buttonInstall)}
                onClick={handleInstall}
              >
                <span> {t("install:installButton")} </span>
              </button>
            )}
            {status === "open" && (
              <button
                className={classNames(css.button, css.buttonInstall)}
                onClick={handleOpen}
              >
                <span> {t("install:open")} </span>
              </button>
            )}
          </section>
          <section className={css.sliderSection}>
            <div className={css.slider}>
              <img
                className={css.slide}
                src="/images/slideForInstall/de/1.png"
                alt="#"
              />
              <img
                className={css.slide}
                src="/images/slideForInstall/de/2.png"
                alt="#"
              />
              <img
                className={css.slide}
                src="/images/slideForInstall/de/3.png"
                alt="#"
              />
            </div>
          </section>
          <section className={css.descriptionSection}>
            <span className={css.title}> {t("install:aboutTitle")} </span>
            <p className={css.text}>{t("install:aboutText")}</p>
          </section>
          <section className={css.descriptionSection}>
            <span className={css.title}> {t("install:ratingTitle")} </span>
            <p className={css.text}>{t("install:ratingText")}</p>
          </section>
          <section className={css.ratingSection}>
            <div className={css.rating}>
              <span className={css.num}> {t("install:appRate")} </span>
              <span className={css.stars} />
              <span className={css.reviews}>
                {" "}
                {t("install:appReviewsFull")}{" "}
              </span>
            </div>
            <div className={css.lines}>
              <div className={css.lineBg}>
                <div className={css.line} />
              </div>
              <div className={css.lineBg}>
                <div className={css.line} />
              </div>
              <div className={css.lineBg}>
                <div className={css.line} />
              </div>
              <div className={css.lineBg}>
                <div className={css.line} />
              </div>
              <div className={css.lineBg}>
                <div className={css.line} />
              </div>
            </div>
          </section>
          <section className={css.commentsSection}>
            {comments.map((item) => {
              return (
                <div className={css.comment} key={`${item.text}-${item.name}`}>
                  <div className={css.header}>
                    <div className={css.user}>
                      <div
                        className={css.avatar}
                        style={{
                          backgroundImage: `url("/images/users/${item.image}")`,
                        }}
                      />
                      <div className={css.info}>
                        <span className={css.name}> {item.name} </span>
                        <span className={css.date}> {item.date} </span>
                      </div>
                    </div>
                    <div className={css.rating} />
                  </div>
                  <div className={css.body}>
                    <p className={css.comment}> {item.text} </p>
                  </div>
                </div>
              );
            })}
          </section>
        </div>
      </div>
      <IOS iosActive={iosActive} setIosActive={setIosActive} />
    </I18nextProvider>
  );
};

export default Install;
