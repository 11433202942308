import React, { useState } from "react";
import css from "../../styles/OtherPages.module.scss";
import containerCss from "../../styles/Container.module.scss";
import classNames from "classnames";
import Limit from "../PredictionLimit";
import IncreaseChance from "../IncraceChance";
import { Trans, useTranslation } from "react-i18next";

const PredictionLimit = ({ setMenu, menu, limit, percent, leadData }) => {
  const [timer, setTimer] = useState("");
  const { t } = useTranslation();
  const depositOptions = t("common:predictionLimit.depositOptions", {
    returnObjects: true,
  });

  return (
    <div className={classNames(css.container, menu === 6 && css._show)}>
      <div className={classNames(css.content, css.predictionsLimit)}>
        <section className={css.backContainer}>
          <button className={css.back} onClick={() => setMenu(1)}>
            <Trans i18nKey="common:predictionLimit.buttons.back" />
          </button>
        </section>
        <div className={css.predictionLimit}>
          <Limit currentLimit={limit} setTimer={setTimer} />
        </div>
        <div className={classNames(containerCss.footer, css.incraseChanse)}>
          <IncreaseChance data={percent} setMenu={setMenu} />
        </div>
        <div className={css.predictions}>
          <div className={css.title}>
            <Trans
              i18nKey="common:predictionLimit.sections.title"
              components={{ span: <span /> }}
            />
          </div>
          {depositOptions.map((option, index) => (
            <div className={css.makeDeposit} key={index}>
              <section className={css.sec1}>
                <div className={css.money}>
                  <div className={css.sum}>
                    {option.amount}
                    <Trans i18nKey="common:predictionLimit.currency" />
                  </div>
                  <div className={css.arrow} />
                  <div className={css.energy}>{option.energy}</div>
                </div>
                <div className={css.chance}>
                  <Trans
                    i18nKey="common:predictionLimit.sections.winChance"
                    components={{ span: <span /> }}
                  />
                  <span> + % </span>
                </div>

                <div
                  className={classNames(css.line, {
                    [css.line_1]: index === 0,
                  })}
                >
                  <div
                    className={
                      index >= 3 ? css.grad_4 : css[`grad_${index + 1}`]
                    }
                  />
                  <div className={css.borderHidden}>
                    <div className={css.h1}> </div>
                    <div className={css.h2}> </div>
                  </div>
                </div>
              </section>
              <section className={css.sec2}>
                <button onClick={() => setMenu(11)}>
                  <Trans
                    i18nKey="common:predictionLimit.buttons.makeDeposit"
                    components={{ span: <span /> }}
                  />
                </button>
              </section>
            </div>
          ))}
        </div>
        <div className={css.free}>
          <div className={css.header}>
            <div className={css.title}>
              <Trans
                i18nKey="common:predictionLimit.sections.freePredictions.header"
                components={{ span: <span /> }}
              />
            </div>
            <div className={css.time}>
              <span> {timer} </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                fill="none"
                viewBox="0 0 17 17"
              >
                <g clipPath="url(#a23we)">
                  <path
                    fill="#fff"
                    fillOpacity=".12"
                    d="M8.5.708A7.792 7.792 0 1 0 16.292 8.5 7.756 7.756 0 0 0 8.5.708Zm4.144 10.271a.743.743 0 0 1-.602.354.672.672 0 0 1-.354-.106L7.792 8.89V4.958a.708.708 0 1 1 1.416 0V8.11l3.188 1.913a.672.672 0 0 1 .248.956Z"
                  />
                  <path
                    fill="url(#b23we)"
                    d="M8.5.708A7.792 7.792 0 1 0 16.292 8.5 7.756 7.756 0 0 0 8.5.708Zm4.144 10.271a.743.743 0 0 1-.602.354.672.672 0 0 1-.354-.106L7.792 8.89V4.958a.708.708 0 1 1 1.416 0V8.11l3.188 1.913a.672.672 0 0 1 .248.956Z"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="b23we"
                    x1=".708"
                    x2="16.292"
                    y1="8.5"
                    y2="8.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F2994A" />
                    <stop offset="1" stopColor="#F2C94C" />
                  </linearGradient>
                  <clipPath id="a23we">
                    <path fill="#fff" d="M0 0h17v17H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className={css.body}>
            <div className={css.energy}>
              <div className={css.bg}> </div>
              <div className={css.num}>
                <span> +10 </span>
              </div>
            </div>
            <div className={css.info}>
              <Trans
                i18nKey="common:predictionLimit.sections.freePredictions.info"
                components={{ span: <span />, br: <br /> }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredictionLimit;
