/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import css from '../styles/Container.module.scss';
import classNames from 'classnames';
import axios from 'axios';
import Card from './Card';
import Coeffs from './Coeffs';
import PreviousRound from './PreviousRound';
import PredictionLimit from './PredictionLimit';
import Confetti from 'react-confetti';
import IncraceChance from './IncraceChance';
import { usePageLeave } from 'react-use';
import { io } from 'socket.io-client';
import { useTranslation } from 'react-i18next';

const Container = ({ data, setMenu, setLimit, setPercent, setLeadData }) => {
    const { t } = useTranslation([ 'common' ]);

    const [ card, setCard ] = useState({ card: 1 });
    const [ oldCoeffs, setOldCoeffs ] = useState([]);
    const [ userData, setUserData ] = useState({});
    const [ socket, setSocket ] = useState(null);
    const [ confettiSizes, setConfettiSizes ] = useState({ width: 0, height: 0 });
    const [ currentLimit, setCurrentLimit ] = useState({});
    const [ firstStart, setFirstStart ] = useState(false);
    const oneWaitingState = useRef(false);
    const waitingRef = useRef(0);
    const coeffsRef = useRef(null);

    usePageLeave(() => {
        getLimit();
        getStory();
    });

    useEffect(() => {
        const handleVisibilityChange = () => {
            if ( document.visibilityState === 'visible' ) {
                getLimit();
                getStory();
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        //todo
        const socket = io(`${process.env.REACT_APP_LINK}`, {
            reconnectionAttempts: 5,
            reconnectionDelay: 5000,
            timeout: 20000,
            transports: [ 'websocket' ],
        });

        setSocket(socket);

        socket.on('connect', () => {
            socket.emit('roundRoom');
        });

        socket.on('round', (data) => {
            console.log({ data });
            try {
                setOldCoeffs(data.history);
                coeffsRef.current = data.history;
            } catch ( error ) {
                console.error('Event handler error:', error);
            }
        });

        socket.on('disconnect', () => {
            console.log('Socket.io connection closed');
        });

        socket.on('connect_error', (error) => {
            setCard({ card: 7 });
            console.error('Connect error:', error);
        });

        // // Пример данных для разных сценариев
        // const mockData = [
        //     // Сценарий: статус "waiting"
        //     { status: 'waiting', predicted: '1.2' },
        //
        //     // Сценарий: статус "play"
        //     { status: 'play', predicted: '1.5' },
        //
        //     // Сценарий: статус "success"
        //     { status: 'success', predicted: '2.0' },
        //
        //     // Сценарий: статус "flew" с выигрышем
        //     { status: 'flew', predicted: '1.8', coefficient: '3.5', win: 1 },
        //
        //     // Сценарий: статус "flew" без выигрыша
        //     { status: 'flew', predicted: '1.8', coefficient: '3.5', win: 0 },
        //
        //     // Сценарий: неизвестный статус
        //     { status: 'unknown', predicted: '0.0' },
        // ];
        //
        // changeStatus(mockData[ 4 ]);

        getLimit();
        getStory();
        getData();
        const body = document.querySelector('body');
        const width = body.offsetWidth;
        const height = body.offsetHeight;

        setConfettiSizes({ width, height });
    }, []);

    const getData = async () => {
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_HOST
                }/v1/api/prediction/client/getData?player_id=${+data.player_id}`
            );

            if ( response.data.success ) {
                setLeadData({ ...response.data.data });
                setUserData({ ...response.data.data });
                setPercent({ ...response.data.data });
                localStorage.setItem(
                    'aviatorUser',
                    JSON.stringify(response?.data?.data)
                );
            }
        } catch ( error ) {
            console.error(error);
        }
    };

    const getStory = async () => {
        const { data: { json, ok } } = await axios.get(`${process.env.REACT_APP_LINK}/api/odds/rounds`);
        console.log({ json, ok });

        if ( !ok ) throw new Error('Network response was not ok');
        setOldCoeffs(json);
        coeffsRef.current = json;
    };

    const getLimit = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_HOST}/v1/api/prediction/limits/${data?.lead_id}`
        );

        if ( !response.ok ) throw new Error('Network response was not ok');
        const result = await response.json();
        setCurrentLimit({ ...result });
        setLimit({ ...result });
    };

    const addLimit = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_HOST}/v1/api/prediction/limits/${data?.lead_id}`,
            {
                method: 'POST',
            }
        );
        if ( !response.ok ) {
            throw new Error('Network response was not ok');
        }
    };

    const getState = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_HOST}/v1/api/prediction/limits/status/${data?.lead_id}`
        );

        if ( !response.ok ) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();

        if ( !!result.limit_status ) {
            if ( !firstStart ) {
                changeStatus({
                    status: 'error',
                });
                setFirstStart(true);

                //todo
                connectSockets();
            } else {
                waitingRef.current = 0;
                changeStatus({
                    status: 'error',
                });
            }
            addLimit();
            oneWaitingState.current = false;
        } else {
            setMenu(6);
        }
    };

    const connectSockets = () => {
        socket.on('round', (data) => {
            try {
                changeStatus(data);
            } catch ( error ) {
                setCard({ card: 7 });
                console.error('Event handler error:', error);
            }
        });
    };

    const changeStatus = (data) => {
        if ( waitingRef.current === 0 ) {
            if ( data.status !== 'waiting' && data.status !== 'error' ) return;
        }
        if ( waitingRef.current === 2 ) {
            setCard({ card: 1 });
            if ( oneWaitingState.current ) {
                setMenu(5);
                getLimit();
                oneWaitingState.current = false;
            }
            return;
        }

        switch ( data.status ) {
            // ожидание ставок
            case 'waiting':
                if ( card.card !== 2 ) {
                    if ( waitingRef.current === 0 ) {
                        waitingRef.current += 1;
                    }
                    setCard({ card: 2, coeff: Number(data?.predicted) });
                }
                break;
            case 'play':
                // игра в процессе самолетик летит
                if ( card.card !== 3 ) {
                    setCard({ card: 3, coeff: Number(data?.predicted) });
                }
                break;
            case 'success':
                // успех конфети
                if ( card.card !== 4 ) {
                    setCard({ card: 4, coeff: Number(data?.predicted) });
                }
                break;
            case 'flew':
                waitingRef.current += 1;
                // самолет долетел и predicted > coefficient, win = 1
                if ( card.card !== 5 && card.card !== 6 ) {
                    if ( data?.win === 1 ) {
                        oneWaitingState.current = true;
                        setCard({
                            card: 5,
                            coeff: Number(data?.predicted),
                            coeff2: Number(data?.coefficient),
                        });
                    } else if ( data?.win === 0 ) {
                        // самолет долетел и predicted < coefficient, win = 0
                        setCard({
                            card: 6,
                            coeff: Number(data?.predicted),
                            coeff2: Number(data?.coefficient),
                        });
                    }
                }
                break;
            default:
                if ( card.card !== 7 ) setCard({ card: 7, coeff: Number(data?.predicted) });
                break;
        }
    };

    return (
        <>
            {card.card === 4 && (
                <Confetti
                    width={confettiSizes.width}
                    height={confettiSizes.height}
                    duration={1500}
                />
            )}
            <div className={css.main}>
                <header className={css.header}>
                    <section className={css.history}>
                        <Coeffs oldCoeffs={oldCoeffs}/>
                        <PreviousRound
                            users={oldCoeffs[ 0 ]?.users}
                            winners={oldCoeffs[ 0 ]?.winners}
                        />
                    </section>
                </header>

                <Card card={card} currentLimit={currentLimit}/>

                <section className={css.actions}>
                    <div
                        className={classNames(
                            css.buttons,
                            css._card_1,
                            card.card === 1 && css._active
                        )}
                    >
                        <button className={classNames(css.b, css._1)} onClick={getState}>
                            <span>
                              -1
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 14 22"
                              >
                                <path
                                    fill="#fff"
                                    d="m6.774 14.006.134-.95H0L7.328 0 6.56 7.412l-.134.95h6.909L6.007 21.419l.767-7.412Z"
                                />
                              </svg>
                                {t('common:container.buttons.predict')}
                            </span>
                        </button>
                        <button
                            className={classNames(css.b, css._2)}
                            onClick={(event) => {
                                event.preventDefault();
                                window.open(
                                    `https://www.raceupcasino.com/de/game/aviator`,
                                    '_blank'
                                );
                            }}
                        >
                            <span>
                                {t('common:container.buttons.aviator')}
                            </span>
                        </button>
                    </div>

                    {[ 2, 3, 4, 5, 6, 7 ].map((cardNumber) => (
                        <div
                            key={cardNumber}
                            className={classNames(
                                css.buttons,
                                css[ `_card_${cardNumber}` ],
                                card.card === cardNumber && css._active
                            )}
                        >
                            <button
                                className={classNames(
                                    css.b,
                                    cardNumber === 4 ? css._flicker : css._2,
                                    cardNumber === 3 && css._21
                                )}
                                onClick={(e) => {
                                    e.preventDefault(); // Предотвращаем стандартное поведение кнопки

                                    if ( cardNumber === 5 || cardNumber === 6 ) {
                                        setMenu(5);
                                    } else {
                                        // Открываем в новом окне с дополнительными параметрами безопасности
                                        const newWindow = window.open(
                                            'https://raceupcasino.com',
                                            '_blank',
                                            'noopener,noreferrer'
                                        );

                                        // Дополнительная проверка на блокировку всплывающих окон
                                        if ( newWindow ) {
                                            newWindow.opener = null;
                                        }
                                    }
                                }}
                            >
                                <span>
                                    {cardNumber === 4
                                        ? t('common:container.buttons.cashOut')
                                        : cardNumber === 5 || cardNumber === 6
                                            ? t('common:container.buttons.increaseChance')
                                            : t('common:container.buttons.aviator')}
                                </span>
                            </button>
                        </div>
                    ))}
                </section>

                <footer className={css.footer}>
                    <PredictionLimit
                        currentLimit={currentLimit}
                        getLimit={getLimit}
                        setMenu={setMenu}
                    />
                    <IncraceChance setMenu={setMenu} data={userData}/>
                </footer>
            </div>
        </>
    );
};

export default Container;
