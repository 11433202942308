/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import css from '../styles/Container.module.scss';
import classNames from 'classnames';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

const Coeffs = ({ oldCoeffs }) => {
    const [ coeffs, setCoeffs ] = useState([]);
    const [ currentCoeff, setCurrentCoeff ] = useState(null);
    const [ transformCurrent, setTransformCurrent ] = useState(0);

    const t = useTranslation([ 'common' ]);

    useEffect(() => {
        if ( oldCoeffs?.[ 0 ]?.coefficient === coeffs?.[ 0 ]?.coefficient ) return;

        if ( coeffs?.length === 0 && oldCoeffs?.length > 0 ) {
            // Первичная загрузка
            setCoeffs(
                oldCoeffs.map((item) => ({
                    ...item,
                    coefficient: Number(item.coefficient),
                }))
            );
        } else if ( oldCoeffs?.length > 0 ) {
            // Проверяем, действительно ли данные изменились
            const hasChanges = oldCoeffs.some((newItem, index) => {
                const oldItem = coeffs[ index ];
                return !oldItem || oldItem.id !== newItem.id || oldItem.coefficient !== newItem.coefficient;
            });

            if ( hasChanges ) {
                setCurrentCoeff(oldCoeffs[ 0 ]);
                const newCoeffs = mergeCoefficients(coeffs, oldCoeffs);
                setCoeffs(newCoeffs);
            }
        }
    }, [ oldCoeffs ]);

    const mergeCoefficients = (oldArray, newArray) => {
        // Берем все элементы кроме первого из новых данных
        const newArrayWithoutFirst = newArray.slice(1);

        // Создаем массив с новыми данными, ограничивая его 9 элементами
        // (так как первый элемент будет добавлен через currentCoeff)
        return newArrayWithoutFirst.slice(0, 9).map(item => ({
            ...item,
            coefficient: Number(item.coefficient)
        }));
    };

    useEffect(() => {
        let width = 0;
        if ( currentCoeff?.coefficient >= 1 && currentCoeff?.coefficient <= 1.99 ) {
            width = 56 + 5;
        } else if (
            (currentCoeff?.coefficient >= 2 && currentCoeff?.coefficient <= 9.99) ||
            (currentCoeff?.coefficient >= 10 && currentCoeff?.coefficient <= 99.99)
        ) {
            width = 65 + 5;
        } else if (
            currentCoeff?.coefficient >= 100 &&
            currentCoeff?.coefficient <= 999.99
        ) {
            width = 75 + 5;
        } else if ( currentCoeff?.coefficient >= 1000 ) {
            width = 100 + 5;
        }
        setTransformCurrent((prev) => prev + width);
    }, [ currentCoeff ]);

    useEffect(() => {
        if ( !transformCurrent ) return;
        setTimeout(() => {
            setCoeffs((prev) => [ currentCoeff, ...prev ].slice(0, 10));
            setCurrentCoeff(null);
            setTransformCurrent(0);
        }, 300);
    }, [ transformCurrent ]);

    return (
        <div className={css.coefficientsContainer}>
            <div
                className={classNames(
                    css.coeff,
                    css.currentCoeff,
                    currentCoeff?.coefficient >= 1 &&
                    currentCoeff?.coefficient <= 1.99 &&
                    css._firstBlue,
                    currentCoeff?.coefficient >= 2 &&
                    currentCoeff?.coefficient <= 9.99 &&
                    css._firstPurple,
                    currentCoeff?.coefficient >= 10 &&
                    currentCoeff?.coefficient <= 99.99 &&
                    css._firstPink,
                    currentCoeff?.coefficient >= 100 &&
                    currentCoeff?.coefficient <= 999.99 &&
                    css._gold &&
                    css._firstGold,
                    currentCoeff?.coefficient >= 1000 && css._gold2 && css._firstGold2
                )}
                style={
                    currentCoeff?.coefficient
                        ? {
                            transform: `translateX(calc(-100% - 5px + ${transformCurrent}px))`,
                        }
                        : {}
                }
            >
                {currentCoeff?.coefficient}
                {!!currentCoeff?.coefficient ? 'x' : ''}
            </div>
            {coeffs.map((item, index) => (
                <div
                    key={`${index}-${item.coefficient}-${item.id}`}
                    className={classNames(
                        css.coeff,
                        item.coefficient >= 1 && item.coefficient <= 1.99 && css._blue,
                        item.coefficient >= 2 && item.coefficient <= 9.99 && css._purple,
                        item.coefficient >= 10 && item.coefficient <= 99.99 && css._pink,
                        item.coefficient >= 100 && item.coefficient <= 999.99 && css._gold,
                        item.coefficient >= 1000 && css._gold2,
                        index === 0 &&
                        item.coefficient >= 1 &&
                        item.coefficient <= 1.99 &&
                        css._firstBlue,
                        index === 0 &&
                        item.coefficient >= 2 &&
                        item.coefficient <= 9.99 &&
                        css._firstPurple,
                        index === 0 &&
                        item.coefficient >= 10 &&
                        item.coefficient <= 99.99 &&
                        css._firstPink,
                        index === 0 &&
                        item.coefficient >= 100 &&
                        item.coefficient <= 999.99 &&
                        css._gold &&
                        css._firstGold,
                        index === 0 &&
                        item.coefficient >= 1000 &&
                        css._gold2 &&
                        css._firstGold2
                    )}
                    id={`coeff-${index}-${item.coefficient}`}
                    style={
                        currentCoeff?.coefficient
                            ? {
                                transform: `translateX(calc(${transformCurrent}px))`,
                            }
                            : {}
                    }
                >
                    {numeral(item.coefficient).format('0.00')}x
                </div>
            ))}
        </div>
    );
};

export default Coeffs;
