import React, { useEffect, useState } from "react";
import Install from "./Install";

const InstallPage = () => {
  const [device, setDevice] = useState({});

  const getDeviceAndBrowser = () => {
    const userAgent = navigator.userAgent;
    let deviceType = "Desktop";
    let browserName = "Unknown";

    if (/android/i.test(userAgent)) {
      deviceType = "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      deviceType = "IOS";
    }

    if (/Macintosh|Mac OS X/i.test(userAgent) && "ontouchend" in document) {
      deviceType = "IOS";
    }

    if (/Macintosh|Mac OS X/i.test(userAgent) && !("ontouchend" in document)) {
      deviceType = "Desktop";
    }

    if (/Windows NT/i.test(userAgent)) {
      deviceType = "Desktop";
    }

    if (/Linux/i.test(userAgent) && !/Android/i.test(userAgent)) {
      deviceType = "Desktop";
    }

    if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") === -1) {
      browserName = "Chrome";
    } else if (
      userAgent.indexOf("Safari") > -1 &&
      userAgent.indexOf("Chrome") === -1
    ) {
      browserName = "Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Firefox";
    } else if (userAgent.indexOf("Edg") > -1) {
      browserName = "Edge";
    } else if (
      userAgent.indexOf("Opera") > -1 ||
      userAgent.indexOf("OPR") > -1
    ) {
      browserName = "Opera";
    } else if (
      userAgent.indexOf("MSIE") > -1 ||
      userAgent.indexOf("Trident") > -1
    ) {
      browserName = "Internet Explorer";
    }

    return {
      deviceType,
      browserName,
    };
  };

  useEffect(() => {
    const { deviceType, browserName } = getDeviceAndBrowser();
    console.log(`deviceType: ${deviceType}, browserName: ${browserName}`);
    setDevice({
      deviceType,
      browserName,
    });
  }, []);

  return (
    <>
      <Install device={device} />
    </>
  );
};

export default InstallPage;
