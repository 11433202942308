/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import css from '../../styles/OtherPages.module.scss';
import classNames from 'classnames';
import numeral from 'numeral';
import PredictionLimit from '../PredictionLimit';
import { Trans } from 'react-i18next';

const IncreaseChance = ({ menu, setMenu, leadData, currentLimit }) => {
    const [ percent, setPercent ] = useState(0);
    const [ showVideo, setShowVideo ] = useState(false);

    useEffect(() => {
        if ( menu !== 5 ) return;
        const perc = JSON.parse(localStorage.getItem('aviatorUser')).percent;
        setPercent(perc);
    }, [ menu ]);

    useEffect(() => {
        const menu = document.querySelector('.menu');
        const head = document.querySelector('.head');

        if ( showVideo ) {
            menu.style.display = 'none';
            head.style.display = 'none';
        } else {
            menu.style.display = 'flex';
            head.style.display = 'flex';
        }

        return () => {
            menu.style.display = 'flex';
            head.style.display = 'flex';
        };
    }, [ showVideo ]);

    return (
        <div className={classNames(css.container, menu === 5 && css._show)}>
            <div className={css.content}>
                <section className={css.backContainer}>
                    <button className={css.back} onClick={() => setMenu(1)}>
                        <Trans i18nKey="common:increaseChance.buttons.backButton"></Trans>
                    </button>
                </section>
                <section className={css.pgContainer}>
                    <div className={css.progressLineContainer}>
                        <div
                            className={css.progress}
                            style={{
                                flexGrow: '1',
                            }}
                        >
                            <div className={css.lineGrad}/>
                            <div
                                className={css.lineShadow}
                                style={
                                    percent <= 0
                                        ? {
                                            '--lineWidth': `calc(${100}% - 16px)`,
                                        }
                                        : percent > 0 && percent <= 16
                                            ? {
                                                '--lineWidth': `calc(${100 - percent}% - 16px)`,
                                            }
                                            : {
                                                '--lineWidth': `calc(${100 - percent}%)`,
                                            }
                                }
                            >
                                <div className={css.lineBg}/>
                            </div>
                            <div
                                className={css.circle}
                                style={
                                    percent <= 0
                                        ? {
                                            left: `calc(${0})%`,
                                        }
                                        : percent > 0 && percent <= 16
                                            ? {
                                                left: `calc(${percent}%)`,
                                            }
                                            : {
                                                left: `calc(${percent}% - 16px)`,
                                            }
                                }
                            ></div>
                        </div>
                        <div
                            className={css.chanceContainer}
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <button
                                className={css.makeDeposit}
                                onClick={(event) => {
                                    event.preventDefault();
                                    window.open(
                                        `https://www.raceupcasino.com/profile/wallet/deposit?currency=eur`,
                                        '_blank'
                                    );
                                }}
                            >
                <span>
                  <Trans
                      i18nKey="common:increaseChance.buttons.makeDeposit"
                      components={{ span: <span/>, br: <br/> }}
                  ></Trans>
                </span>
                            </button>
                            <div className={css.proc}>
                                <span> {numeral(percent).format('0.00')} % </span>
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                  <Trans i18nKey="common:increaseChance.progress.chance"></Trans>
                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={css.wining}>
                    <div className={css.title}>
                        <Trans
                            i18nKey="common:increaseChance.sections.title"
                            components={{ span: <span/>, br: <br/> }}
                        ></Trans>
                    </div>
                    <p className={css.text2}>
                        <Trans
                            i18nKey="common:increaseChance.sections.text"
                            components={{ span: <span/>, br: <br/> }}
                        ></Trans>
                    </p>
                </section>
                <section className={css.predictionLimitIS}>
                    <div className={css.image}>
                        <img src="/images/box.png"/>
                    </div>
                    <div className={css.info}>
            <span>
              <Trans
                  i18nKey="common:increaseChance.predictionLimit.info"
                  components={{ br: <br/> }}
              ></Trans>
            </span>

                        <button onClick={() => setMenu(6)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="15"
                                fill="none"
                                viewBox="0 0 9 15"
                            >
                                <g fill="#2F313E">
                                    <path
                                        d="M3.872 9.085V9.08L.51 9.205a.201.201 0 0 1-.186-.293L4.414.938c.1-.194.394-.113.38.105l-.316 4.803v.007l3.361-.126a.201.201 0 0 1 .187.293l-4.09 7.974c-.1.194-.394.113-.38-.105l.316-4.803Z"/>
                                    <path
                                        d="m4.34 8.64-.05.48-.245 3.74-.327 1.239a.197.197 0 0 1-.162-.21l.316-4.804.468-.445ZM4.653.837l-.348 1.235L.869 8.77l-.538.337a.2.2 0 0 1-.007-.195L4.414.937a.197.197 0 0 1 .24-.1ZM8.02 5.825l-.54.335-3.471.13.468-.438 3.362-.126a.2.2 0 0 1 .18.099Z"/>
                                    <path
                                        d="m4.29 9.12.05-.48-3.471.13 3.435-6.699-.245 3.74-.05.48 3.472-.13-3.436 6.698.246-3.739Z"/>
                                </g>
                            </svg>
                            <Trans i18nKey="common:increaseChance.predictionLimit.button"></Trans>
                        </button>
                    </div>
                </section>
                <section className={css.limitSection}>
                    <PredictionLimit currentLimit={currentLimit} title={false}/>
                </section>
                <section
                    className={css.buttons}
                    style={{
                        marginTop: '16px',
                    }}
                >
                    <button
                        className="uiButton"
                        style={{
                            padding: '0px',
                            fontSize: '14px',
                        }}
                        onClick={(event) => {
                            event.preventDefault();
                            window.open(
                                `https://www.raceupcasino.com/profile/wallet/deposit?currency=eur`,
                                '_blank'
                            );
                        }}
                    >
                        <Trans i18nKey="common:increaseChance.buttons.makeDeposit"></Trans>
                    </button>
                    <button
                        className="uiButton uiButton_2"
                        style={{
                            fontSize: '14px',
                        }}
                        onClick={() =>
                            window.open(`https://t.me/${leadData.username}`, '_blank')
                        }
                    >
                        <Trans i18nKey="common:increaseChance.buttons.help"></Trans>
                    </button>
                </section>
            </div>
        </div>
    );
};

export default IncreaseChance;
